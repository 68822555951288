import { FC } from 'react'
import classnames from 'classnames'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { Button } from 'ccs-react-lib'

import Summary from '../../overview/summary/Summary'
import IPaymentInfo from './PaymentInfo.types'
import PaymentModal from '../../overview/paymentModal/PaymentModal'

import './PaymentInfo.scss'

const PaymentInfo: FC<IPaymentInfo> = (props) => {
  const {
    id,
    className,
    cardNumber,
    nextBillingDate,
    openPaymentModal,
    cardIsExpired,
    setOpenPaymentModal,
  } = props
  const { t } = useTranslation()

  const billingInfo = () =>
    cardNumber && (
      <Grid container direction="row">
        <Grid item xs={12} sm={6}>
          <Summary
            id={'card-number'}
            cardNumber={cardNumber}
            nextBillingDate={nextBillingDate}
            cardIsExpired={cardIsExpired}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className={'quick-links'}>
            <Typography
              variant="body2"
              data-test="update-payment-method-link"
              onClick={() => setOpenPaymentModal?.(true)}
            >
              <Button
                id={'link-payment-modal'}
                variant="tertiary"
                className="update-payment-btn"
                onClick={() => setOpenPaymentModal?.(true)}
                tabIndex={0}
                content={t('overview.links.updatePayment')}
                buttonProps={{ id: `update-payment-info` }}
              />
            </Typography>
          </div>
        </Grid>
      </Grid>
    )

  const noPaymentMethodInfo = () => (
    <Grid container direction="row" className="">
      <Grid item xs={12} sm={12}>
        <Typography
          data-test="no-payment-method-message"
          className="no-payment-method-message"
          variant="body2"
        >
          {t('planManager.paymentConfiguration.InfoTextWhenCardEmpty')}
        </Typography>
        <Button
          id={'add-payment-method'}
          data-test="add-payment-method"
          variant="secondary"
          className="mt_20"
          onClick={() => setOpenPaymentModal?.(true)}
          tabIndex={0}
          content={t('planManager.paymentConfiguration.addPaymentButtonText')}
          buttonProps={{ id: `add-payment-info` }}
        />
      </Grid>
    </Grid>
  )
  return (
    <div
      id={id}
      className={classnames('payment-info payment-info-wrapper', className)}
      data-test="payment-info-component"
    >
      {cardNumber ? billingInfo() : noPaymentMethodInfo()}
      {openPaymentModal && (
        <PaymentModal id={'payment-modal'} open={openPaymentModal} onClose={setOpenPaymentModal} />
      )}
    </div>
  )
}

export default PaymentInfo
