import { FC } from 'react'
import classnames from 'classnames'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'
import { Button } from 'ccs-react-lib'
import { Badge } from '@mui/material'
import size from 'lodash/size'
import isNil from 'lodash/isNil'

import Modal from '../../../../shared/components/modal/Modal'
import IOrderConfirmationModal from './OrderConfirmationModal.types'
import { getCurrencySymbol, replaceParameters } from '../../../../../utility/CommonUtility'
import { dateFormatter } from '../../../../../utility/DateUtility'
import { StoreHelper } from '../../../../../helpers/features/StoreHelper'

import './OrderConfirmationModal.scss'

const OrderConfirmationModal: FC<IOrderConfirmationModal> = (props) => {
  const {
    id,
    className,
    open = false,
    orderInfo,
    currency,
    contractStatus,
    onClose,
    onSubmit,
    callbackValue,
  } = props
  const { t } = useTranslation()
  const tableData = orderInfo?.items ?? []

  const renderDesktopInfoTableRow = () => {
    return tableData?.map((orderitem: any, index: number) => {
      const itemStatus =
        orderitem?.totalNet > 0
          ? t('orderConfirmationModal.addedText')
          : t('orderConfirmationModal.removedText')
      return (
        <TableRow
          key={index}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          data-test="table-row"
          className="order-info__row"
        >
          {orderitem?.pricePerUnit > 0 && (
            <>
              {contractStatus !== StoreHelper.CONTRACT_STATUS.TRIAL && (
                <TableCell align="left">
                  <Badge
                    id={'order-info-status'}
                    className="badge badge--light order-info__status"
                    color={
                      itemStatus === t('orderConfirmationModal.addedText') ? 'success' : 'error'
                    }
                    //weight={'light'} TODO: update it when we replace badge with std-lib badge
                    badgeContent={itemStatus}
                  />
                </TableCell>
              )}
              <TableCell align="left" className="order-info__col">
                {orderitem?.description}
              </TableCell>
              <TableCell align="left" className="order-info__col">
                {orderitem?.scaleAmount} {t('orderConfirmationModal.seatText')}
              </TableCell>
              <TableCell align="left" className="order-info__col">
                {`${getCurrencySymbol(currency || 'EUR')} ${orderitem?.pricePerUnit.toFixed(2)}`} /
                {t('orderConfirmationModal.monthInfoText')}
              </TableCell>
              <TableCell align="right" className="order-info__col">
                {orderitem?.vatPercentage && orderitem?.vatPercentage > 0
                  ? `${t('orderConfirmationModal.vatText')}(${orderitem?.vatPercentage}%)`
                  : ' '}
              </TableCell>
              <TableCell align="right" className="order-info__col">
                {`${getCurrencySymbol(currency || 'EUR')} ${orderitem?.totalNet.toFixed(2)}`}
              </TableCell>
            </>
          )}
        </TableRow>
      )
    })
  }

  const renderMobileInfoTableRow = () => {
    return tableData?.map((orderitem: any, index: number) => {
      const itemStatus =
        orderitem?.totalNet > 0
          ? t('orderConfirmationModal.addedText')
          : t('orderConfirmationModal.removedText')
      return (
        <TableRow
          key={index}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          data-test="table-row"
          className="order-info__row"
        >
          {orderitem?.pricePerUnit > 0 && (
            <>
              <TableCell align="left" className="plan-info">
                {contractStatus !== StoreHelper.CONTRACT_STATUS.TRIAL && (
                  <Badge
                    id={'order-info-status'}
                    className="badge badge--light order-info__status"
                    color={
                      itemStatus === t('orderConfirmationModal.addedText') ? 'success' : 'error'
                    }
                    //weight={'light'} TODO: update it when we we replace badge with std-lib badge
                    badgeContent={itemStatus}
                  />
                )}
                <div>{orderitem?.description}</div>
                <div>
                  {orderitem?.scaleAmount} {t('orderConfirmationModal.seatText')}
                </div>
                <div>
                  {' '}
                  {`${getCurrencySymbol(currency || 'EUR')} ${orderitem?.pricePerUnit.toFixed(
                    2
                  )}`}{' '}
                  / {t('orderConfirmationModal.monthInfoText')}
                </div>
              </TableCell>
              <TableCell align="right" className="order-info__col">
                {orderitem?.vatPercentage && orderitem?.vatPercentage > 0
                  ? `${t('orderConfirmationModal.vatText')}(${orderitem?.vatPercentage}%)`
                  : ' '}
              </TableCell>
              <TableCell align="right" className="order-info__col">
                {`${getCurrencySymbol(currency || 'EUR')}${orderitem?.totalNet.toFixed(2)}`}
              </TableCell>
            </>
          )}
        </TableRow>
      )
    })
  }

  const renderInfoTable = () => {
    return (
      <TableContainer>
        <Table id={id} className={classnames('order-info', className)} data-test="table-component">
          <TableBody>
            {size(tableData) > 0 ? (
              <>
                <div className="order-info__desktop" data-test="order-info-items-content">
                  {renderDesktopInfoTableRow()}
                </div>
                <div className="order-info__mobile" data-test="order-info-items-content">
                  {renderMobileInfoTableRow()}
                </div>
              </>
            ) : (
              <div className="order-info__no-data-message" data-test="order-info-no-data-message">
                {t('orderConfirmationModal.noDataMessage')}
              </div>
            )}
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              data-test="table-row"
              className="order-info__row"
            >
              {!isNil(orderInfo?.totalVat) && orderInfo?.totalVat !== 0 && (
                <TableCell align="right" colSpan={6}>
                  <div data-test="order-info-total">
                    {`${getCurrencySymbol(currency || 'EUR')} ${orderInfo?.total?.toFixed(2)}`}
                  </div>

                  <div className="order-tax-info" data-test="order-info-vat">
                    {t('orderConfirmationModal.taxText')}{' '}
                    {`${getCurrencySymbol(currency || 'EUR')} ${orderInfo?.totalVat?.toFixed(2)}`}
                  </div>
                </TableCell>
              )}
            </TableRow>

            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              data-test="table-row"
              className="order-info__row"
            >
              <TableCell align="right" colSpan={6}>
                {t('orderConfirmationModal.grossTotalText')}{' '}
                <span className="order-info__gross-value">{`${getCurrencySymbol(
                  currency || 'EUR'
                )} ${orderInfo?.totalGross?.toFixed(2)}`}</span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
  return (
    <Modal
      id={id}
      className={classnames('order-confirmation-modal', className)}
      open={open}
      size="medium"
      onClose={() => onClose(false)}
      title={t('orderConfirmationModal.title')}
      titleClassName="order-confirmation-modal__title"
      data-test="order-confirmation-modal-component"
    >
      <div
        id="order-confirmation-content"
        className="order-confirmation-modal__content"
        data-test="order-confirmation-modal-content"
      >
        <div
          className="order-confirmation-modal__banner"
          data-test="order-confirmation-modal-banner"
        >
          {replaceParameters(t('orderConfirmationModal.changeDateText'), {
            changeDate: dateFormatter(orderInfo?.changeDate),
          })}
        </div>
        <div className="order-confirmation-modal__order-list">{renderInfoTable()}</div>
        <div
          className="order-confirmation-modal__banner"
          data-test="order-confirmation-modal-banner"
        >
          {contractStatus === StoreHelper.CONTRACT_STATUS.TRIAL ? (
            <>
              {replaceParameters(t('orderConfirmationModal.trialEndDateText'), {
                trialEndDate: dateFormatter(orderInfo?.trialEnds),
                amount: `${getCurrencySymbol(
                  currency || 'EUR'
                )} ${orderInfo?.nextInvoiceTotal?.toFixed(2)}`,
              })}
            </>
          ) : (
            <>
              {replaceParameters(t('orderConfirmationModal.nextBillingDateText'), {
                nextBillingDate: dateFormatter(orderInfo?.nextInvoiceDate),
                amount: `${getCurrencySymbol(
                  currency || 'EUR'
                )} ${orderInfo?.nextInvoiceTotal?.toFixed(2)}`,
              })}
            </>
          )}
        </div>
      </div>
      <div className="order-confirmation-modal__btn-wrapper">
        <Button
          id={'order-confirmation-modal-btn'}
          className="order-confirmation-modal__btn mr_20"
          data-test="order-confirmation-modal-submit-button"
          onClick={onSubmit}
          callbackValue={callbackValue}
          content={t('planManager.formButton.purchase')}
          buttonProps={{ id: `order-purchase` }}
        />

        <Button
          id={'cancel'}
          className="order-confirmation-modal__btn"
          onClick={() => onClose(false)}
          data-test="order-confirmation-modal-cancel-button"
          content={t('planManager.formButton.cancel')}
          variant="tertiary"
          buttonProps={{ id: `discard-changes` }}
        />
      </div>
    </Modal>
  )
}
export default OrderConfirmationModal
