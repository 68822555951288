import { FC } from 'react'
import classnames from 'classnames'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'
import { Button } from 'ccs-react-lib'

import ILicenseInfoDisabled from './LicenseInfoDisabled.types'
import { StoreHelper } from '../../../../../helpers/features/StoreHelper'
import i18n from '../../../../../translations/i18n'

import './LicenseInfoDisabled.scss'

const LicenseInfoDisabled: FC<ILicenseInfoDisabled> = (props) => {
  const { id, className } = props
  const { t } = useTranslation()
  const handlePlanButtonClick = () => {
    if (i18n.language == 'de') {
      window.open(StoreHelper.LEARN_MORE_LINK.de, '_blank')
    } else {
      window.open(StoreHelper.LEARN_MORE_LINK.en, '_blank')
    }
  }
  return (
    <div
      id={id}
      className={classnames('license-info license-info__disabled', className)}
      data-test="license-info-disabled-component"
    >
      <Grid container rowSpacing={2.5} data-test="plan-info">
        <Grid item xs={12} sm={12} className="row-item">
          <Button
            id={'add-payment-method'}
            data-test="add-payment-method"
            tabIndex={0}
            content={t('planManager.licenseConfiguration.disabledLicense.contactUsButton')}
            onClick={handlePlanButtonClick}
            buttonProps={{ id: `contact-us-button` }}
          />
        </Grid>
        <Grid item xs={12} md={12} data-test="note">
          <p className="license-info__note license-info__disabled_note">
            {t('planManager.licenseConfiguration.disabledLicense.note')}
          </p>
        </Grid>
      </Grid>
    </div>
  )
}

export default LicenseInfoDisabled
