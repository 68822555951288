import { FC } from 'react'
import Grid from '@mui/material/Grid'
import classnames from 'classnames'

import PlanInfo from '../planInfo/PlanInfo'
import IPlanList from './PlanList.types'
import { StoreHelper } from '../../../../../../helpers/features/StoreHelper'

import './PlanList.scss'

const PlanList: FC<IPlanList> = (props) => {
  const { planList, handlePlanItemClick, activePlanType } = props
  const planTypeClass =
    activePlanType === StoreHelper.PLAN_TYPES.ENTERPRISE ||
    activePlanType === StoreHelper.PLAN_TYPES.ENTERPRISE_ACTIVE ||
    activePlanType === StoreHelper.PLAN_TYPES.EDUCATION
      ? 'en-plan'
      : ''
  return (
    <>
      {planList?.map((plan) => (
        <Grid key={plan.id} item xs={12} sm={4} className={classnames('plan-grid', planTypeClass)}>
          <PlanInfo
            id={plan.id}
            title={plan.title}
            price={plan.price}
            isActive={plan?.isActive}
            description={plan.description}
            subDescription={plan.subDescription}
            buttonText={plan.buttonText}
            onClick={handlePlanItemClick}
            callbackValue={{ id: plan.id, title: plan.title, isActive: plan?.isActive }}
            currencyVisibility={plan.currencyVisibility}
            activeLabel={plan?.activeLabel}
            currency={plan.currency}
          />
        </Grid>
      ))}
    </>
  )
}

export default PlanList
