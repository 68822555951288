import { FC, useEffect, useContext, useState } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Button } from 'ccs-react-lib'

import Modal from '../../../../shared/components/modal/Modal'
import IPaymentModal from './PaymentModal.types'
import i18n from '../../../../../translations/i18n'
import { getMetaValueByTagName } from '../../../../../utility/CommonUtility'
import { StoreContext } from '../../../Store.context'

import './PaymentModal.scss'

declare let SubscriptionJS: any

const PaymentModal: FC<IPaymentModal> = (props) => {
  const { id, className, open = false, onClose } = props
  const { t } = useTranslation()
  const {
    paymentModalErrorMessage,
    paymentFormErrorCallback,
    handlePaymentFormSubmit,
    setPaymentModalErrorMessage,
  } = useContext(StoreContext)
  const [paymentFormData, setPaymentFormData] = useState()
  const [paymentFormLoading, setPaymentFormLoading] = useState(false)
  const subscriptionJS = SubscriptionJS
  const publicApiKey = process.env.REACT_APP_BILLWERK_PUBLIC_API_KEY
    ? process.env.REACT_APP_BILLWERK_PUBLIC_API_KEY
    : getMetaValueByTagName('REACT_APP_BILLWERK_PUBLIC_API_KEY')

  /**
   * basic styyling for the billwerk payment form
   */
  const style = {
    body: { margin: '-15px 0 0 0', padding: 0 },
    label: { fontSize: '12px', fontFamily: 'Open Sans', color: '#404040', padding: '0px' },
    input: {
      fontSize: '12px',
      fontFamily: 'Open Sans',
      backgroundColor: '#fff',
      borderRadius: '4px',
      borderColor: '#eaeaea',
      color: '#404040',
      height: '48px',
      borderWidth: '2px',
    },
    inputRequired: {},
    inputInvalid: {
      borderColor: '#ff4f2a',
    },
    selectInvalid: { borderBottomColor: '#ff4f2a' },
    selectRequired: { borderBottomColor: '#ff4f2a' },
  }

  /**
   * configuration for the billwerk payment form
   */
  const config = {
    paymentMethods: ['CreditCard:FakePSP'], //TODO: replace with the correct data
    publicApiKey: publicApiKey,
    locale: i18n.language,
    providerReturnUrl: '', //TODO: replace with the return url
  }

  /**
   * render the billwerk iframe form
   */
  const showPaymentForm = () => {
    const paymentForm = subscriptionJS.createElement(
      'paymentForm',
      'payment',
      config,
      style,
      paymentFormErrorCallback
    )
    setPaymentFormData(paymentForm)
  }

  addEventListener('DOMNodeInserted', function (event: any) {
    if (event.target.tagName == 'IFRAME') {
      setPaymentFormLoading(false)
    }
  })

  /**
   * remove the iframe from the dom
   */
  const unsetIframe = () => {
    document.querySelectorAll('iframe').forEach(function (elem) {
      elem.parentNode?.removeChild(elem)
    })
  }

  useEffect(() => {
    if (open) {
      unsetIframe()
      setPaymentModalErrorMessage?.('')
      setTimeout(showPaymentForm)
    }
  }, [open])

  const errorMessageElement = () => {
    return (
      <div id="payment-modal-message" className="payment-modal__message">
        {paymentModalErrorMessage}
      </div>
    )
  }

  const loadingElement = () => {
    return (
      <div id="payment-modal-loading" className="payment-modal__loading text--center">
        {t('paymentModal.loadingText')}
      </div>
    )
  }

  return (
    <Modal
      id={id}
      className={classnames('payment-modal', className)}
      open={open}
      size="small"
      onClose={() => onClose(false)}
      title={t('paymentModal.title')}
      titleClassName="payment-modal__title"
      data-test="payment-modal-component"
    >
      <div className="payment-modal__content-wrapper">
        {!paymentFormLoading && (
          <p className="payment-info__note">
            {t('planManager.licenseConfiguration.requiredNote')}
            <span className="payment-info__required">*</span>
          </p>
        )}
        <div
          id="payment"
          className="payment-modal__content"
          data-test="payment-modal-content"
        ></div>
        {paymentFormLoading && loadingElement()}
        {paymentModalErrorMessage && errorMessageElement()}
      </div>
      <div className="payment-modal__btn-wrapper">
        <Button
          id={'payment-modal-btn'}
          className="payment-modal__btn mr_20"
          onClick={() => handlePaymentFormSubmit?.(paymentFormData)}
          data-test="payment-modal-action-button"
          content={t('paymentModal.buttonText')}
          buttonProps={{ id: `save-changes` }}
        />

        <Button
          id={'cancel'}
          className="payment-modal__btn"
          onClick={() => onClose(false)}
          data-test="cancel-add-payment-method"
          content={t('paymentModal.cancel')}
          variant="tertiary"
          buttonProps={{ id: `cancel-changes` }}
        />
      </div>
    </Modal>
  )
}
export default PaymentModal
