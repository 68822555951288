import { FC } from 'react'
import classnames from 'classnames'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import size from 'lodash/size'
import { useTranslation } from 'react-i18next'
import { Button, Pagination } from 'ccs-react-lib'

import { dateFormatter } from '../../../../../utility/DateUtility'
import { getCurrencySymbol } from '../../../../../utility/CommonUtility'
import IInvoiceTable from './InvoiceTable.types'

import './InvoiceTable.scss'

const InvoiceTable: FC<IInvoiceTable> = (props) => {
  const {
    id,
    className,
    tableData,
    activePage,
    pageCount,
    paginationLabels,
    handlePaginationChange,
    handleInvoicesDownloadClick,
  } = props
  const { t } = useTranslation()

  const optionActions = (invoieId: string, referenceNumber: string) => {
    return (
      <div className="invoice-table__actions" data-test="table-row-action">
        {/* 
        TODO: in the future
        <Button
          id={'view-action'}
          className="mr_10 mt_5 mb_5"
          color="secondary"
          onClick={handleInvoicesViewClick}
          callbackValue={invoieId}
        >
          View
        </Button>*/}
        <Button
          id={'download-action'}
          content={t('invoiceManager.downloadButtonText')}
          variant="secondary"
          callbackValue={{ id: invoieId, name: referenceNumber }}
          onClick={handleInvoicesDownloadClick}
          buttonProps={{ id: `download-invoice` }}
        />
      </div>
    )
  }

  const tableHeader = () => {
    return (
      <TableRow data-test="table-header">
        <TableCell align="left">{t('invoiceManager.tableHeadDate')}</TableCell>
        <TableCell align="left">{t('invoiceManager.tableHeadReferenceNumber')}</TableCell>
        <TableCell align="left">{t('invoiceManager.tableHeadTotal')}</TableCell>
        <TableCell align="left">{t('invoiceManager.tableHeadOptions')}</TableCell>
      </TableRow>
    )
  }

  const tableDataRow = () => {
    return tableData?.map((row: any) => {
      return (
        <TableRow
          key={row.id}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          data-test="table-row"
        >
          <TableCell align="left">{dateFormatter(row.issuedDate)}</TableCell>
          <TableCell align="left">{row.referenceNumber}</TableCell>
          <TableCell align="left">
            {`${getCurrencySymbol(row.currency)}${row.totalAmount.toFixed(2)}`}
          </TableCell>
          <TableCell align="left">{optionActions(row.id, row.referenceNumber)}</TableCell>
        </TableRow>
      )
    })
  }

  const mobileView = () => {
    return tableData?.map((row: any) => {
      return (
        <Grid
          key={row.id}
          container
          direction="row"
          className="invoice-table__mobile-row"
          data-test="table-row"
        >
          <Grid item xs={4}>
            <Typography variant="body2" className="invoice-table__date">
              {dateFormatter(row.issuedDate)}
            </Typography>
            <Typography variant="body2">{row.referenceNumber}</Typography>
            <Typography variant="body2">
              {`${getCurrencySymbol(row.currency)}${row.totalAmount.toFixed(2)}`}
            </Typography>
          </Grid>
          <Grid item xs={8} className="text--right">
            {optionActions(row.id, row.referenceNumber)}
          </Grid>
        </Grid>
      )
    })
  }

  return (
    <div
      id={id}
      className={classnames('invoice-table-wrapper mb_20', className)}
      data-test="invoice-table-component"
    >
      {size(tableData) > 0 && (
        <>
          <div className="invoice-table__section">
            <TableContainer className="invoice-table__desktop">
              <Table
                id={id}
                className={classnames('invoice-table', className)}
                data-test="table-component"
              >
                <TableHead>{tableHeader()}</TableHead>
                <TableBody>{tableDataRow()}</TableBody>
              </Table>
            </TableContainer>

            <div className="invoice-table__mobile">{mobileView()}</div>
          </div>
          {!!pageCount && pageCount > 1 && (
            <>
              <div className="invoice-table__desktop">
                <Pagination
                  id={'invoice-table-pagination'}
                  className={'invoice-table__pagination mt_40'}
                  count={pageCount}
                  siblingCount={0}
                  boundaryCount={1}
                  onChange={handlePaginationChange}
                  currentPage={activePage}
                  nextButtonText={paginationLabels?.nextButtonText}
                  previousButtonText={paginationLabels?.previousButtonText}
                  length="large"
                />
              </div>
              <div className="invoice-table__mobile">
                <Pagination
                  id={'invoice-table-pagination'}
                  className={'invoice-table__pagination mt_40'}
                  count={pageCount}
                  siblingCount={0}
                  boundaryCount={1}
                  onChange={handlePaginationChange}
                  currentPage={activePage}
                  length="medium"
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default InvoiceTable
