import { FC } from 'react'
import classnames from 'classnames'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { dateFormatter } from '../../../../../utility/DateUtility'
import ISummary from './Summary.types'
import { StoreHelper } from '../../../../../helpers/features/StoreHelper'

import './Summary.scss'

const Summary: FC<ISummary> = (props) => {
  const {
    id,
    className,
    cardNumber,
    nextBillingDate,
    cardIsExpired,
    paymentProvider,
    planType,
    isHidePaymentInfo,
  } = props
  const { t } = useTranslation()

  return (
    <div id={id} className={classnames('summary', className)} data-test="summary-component">
      {paymentProvider === 'InvoicePayment' &&
        (planType === StoreHelper.PLAN_TYPES.ENTERPRISE ||
          planType === StoreHelper.PLAN_TYPES.EDUCATION ||
          planType === StoreHelper.PLAN_TYPES.ENTERPRISE_ACTIVE) && (
          <Typography variant="body2" data-test="on-account">
            <b> {t('overview.onAccountPayment')}</b>
          </Typography>
        )}
      {isHidePaymentInfo && (
        <Typography variant="body2" data-test="on-account">
          <b> {t('overview.onAccountPayment')}</b>
        </Typography>
      )}
      {cardNumber &&
        !isHidePaymentInfo &&
        (planType !== StoreHelper.PLAN_TYPES.ENTERPRISE ||
          planType !== StoreHelper.PLAN_TYPES.EDUCATION ||
          planType !== StoreHelper.PLAN_TYPES.ENTERPRISE_ACTIVE) && (
          <Typography variant="body2" data-test="payment-card">
            {t('overview.creditCardInfo')} xxxx-xxxx-xxxx {cardNumber}{' '}
            {cardIsExpired && (
              <span className="summary__expired-card">
                {t('planManager.paymentConfiguration.errorTextWhenCardExpired')}
              </span>
            )}
          </Typography>
        )}
      {nextBillingDate && (
        <Typography variant="body2" data-test="invoice-next-date">
          {t('overview.nextBillingDate')} {dateFormatter(nextBillingDate)}
        </Typography>
      )}
    </div>
  )
}

export default Summary
